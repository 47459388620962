<template>
  <div style="padding-bottom:20px">
    <div class="card">
      <div class="title">
        考试信息
      </div>
      <div class="content">
        <Info ref="InfoRef"
              :testPaperInfo="testPaperInfo" />
      </div>
    </div>

    <div class="card">
      <div class="title">
        学生成绩排名
      </div>
      <div class="  ">
        <Sort ref="SortRef" />
      </div>
    </div>

    <div class="card">
      <div class="title">
        学生成绩正态分布
      </div>
      <div class="content">
        <Zhengtai ref="ZhengtaiRef" />
      </div>
    </div>

    <div class="card">
      <div class="title">
        班级水平分析
      </div>
      <div class="content">
        <ClassFx ref="ClassFxRef" />
      </div>
    </div>

    <div class="card">
      <div class="title">
        题目考点得分率
      </div>
      <div class="content">
        <QuestionKnowledge ref="questionKnowledge" />
      </div>
    </div>

    <div class="card">
      <div class="title">
        试卷考点得分率
      </div>
      <div class="content">
        <PaperKnowledge ref="paperKnowledge" />
      </div>
    </div>

  </div>
</template>

<script>
import { getTestPaperInfo } from '@/api/analyze.js'
import Info from './analyzeComponents/info.vue'
import Sort from './analyzeComponents/sort.vue'
import Zhengtai from './analyzeComponents/zhengtai.vue'
import ClassFx from './analyzeComponents/classfx.vue'
import QuestionKnowledge from './analyzeComponents/questionKnowledge.vue'
import PaperKnowledge from './analyzeComponents/paperKnowledges.vue'
export default {
  data () {
    return {
      testPaperInfo: {},
    }
  },
  components: {
    Info, Sort, Zhengtai, ClassFx,
    QuestionKnowledge, PaperKnowledge
  },
  mounted () {
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    this.initData()
  },
  methods: {
    initData () {
      let params = {
        testPaperId: this.$route.query.test_paper_id
      }
      this.getIfo(params)
      this.getSort(params)

    },
    async getIfo (params) {
      const { data } = await getTestPaperInfo(params)
      this.testPaperInfo = data
    },
    async getSort (params) {

    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  background: white;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  margin-top: 20px;
  .title {
    width: 100%;
    height: 50px;
    background: #eff8ff;
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
    text-indent: 10px;
  }
}
</style>